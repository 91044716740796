import DetailsForm from "./Components/client/UserLogin";
import CorporateForm from "./Components/corporate/Corporate";
import EumletLink from "./Components/Lean/Lean-api";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import EumletLinkCorporate from "./Components/Lean/Lean-api-c";

function App() {
  return (
    <Router>
      <main>
        <Routes>
          <Route path="/" element={<DetailsForm />} />
          {/* <Route path="/dashboard" element={<Dashboardd />} /> */}
          <Route path="/user" element={<EumletLink />} />
          <Route path="/corporate" element={<CorporateForm />} />
          <Route path="/corporate/user" element={<EumletLinkCorporate/>} />

        </Routes>
      </main>
    </Router>
  );
}

export default App;
