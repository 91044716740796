import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

// import './App.css';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import "./App.css";
// import logo from "./eumlet_white.png";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const EumletLinkCorporate: React.FC = () => {
  const [linkResp, setlinkResp] = useState("");
  let query = useQuery();
  let redirect_url = query.get("redirect_url");
  let nonce = query.get("nonce");
  // let customer_id = "750aca1b-5188-457b-a97e-09834ea6a136";
  // let customer_id = "c1874c88-fdc0-41ae-acc4-29c04da49369";
  // let corporate = query.get("corporate")
  let end_user_id = query.get("end_user_id");
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies(["rtbs_token"]);
  const [customer_id, setCustomer_id] = useState<any>("");
  const [endUserId, setEndUserId] = useState<any>("")
  const [tc, setTc] = useState<boolean>(true);

  useEffect(() => {
    // Check if the rtbs_token cookie exists
    if (cookies.rtbs_token) {
      const authorizationToken = `Bearer ${cookies.rtbs_token}`;

      fetch("https://api.eumlet.com/api/v1/finance/lean/customer/", {
        method: "GET",
        headers: {
          Authorization: authorizationToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("customer id rc", data.customer_id);
          console.log("data", data);

          if (data && data.customer_id) {
            console.log("customer id cl", data.customer_id);

            setCustomer_id(data.customer_id);
            setEndUserId(data.end_user_id)

          } else {
            console.error("Customer ID not found in response", data);
          }

          // console.log("cus",data.customer_id)
          // set data to local storage
        })

        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [cookies.rtbs_token, customer_id]);

  // console.log(corporate)
  const myCallback = (data: any) => {
    setlinkResp(data.status);
    console.log(data);
    if (data.status == "SUCCESS") {
      alert("information submitted successfully");
      // window.location.href = `${redirect_url}?link_response=${data.status}&nonce=${nonce}`;
    }
  };

  const handleScriptReady = () => {
    if ((window as any).Lean) {
      (window as any).Lean.connect({
        app_token: "" + process.env.REACT_APP_CORPORATE_LEAN_APPLICATION_TOKEN,

        permissions: [
          "accounts",
          "transactions",
          "balance",
        ],
        customer_id: customer_id,
        end_user_id: endUserId,
        callback: myCallback,
      });
    }
  };

  // useEffect(() => {}, []);

  const handleAccept = () => {
    setLoading(true);
    const script = document.createElement("script");
    script.src = "https://cdn.leantech.me/link/sdk/web/latest/Lean.min.js";
    script.async = true;
    script.onload = () => {
      handleScriptReady();
    };
    document.body.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  };

  const handleDecline = () => {
    // Redirects to redirect_url parameter
    // const authorization_code = "rejected";
    // // window.location.href = `${redirect_url}?authorization_code=${authorization_code}`;
    // // var url = window.location.href.split('?')[0];
    // // console.log(url);
    // window.location.href = 'http://localhost:3000/null?link_response=ERROR&nonce=null';
  };

  const loggedInView = (
    <>
      <div>
        <div className="max-w-xl p-6 bg-primary border border-gray-500 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <a href="#">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-white">
              Personal Information Authorization
            </h5>
          </a>
          <p className="mb-3 font-normal  text-gray-400">
            In order to deliver a personalized, efficient, and secure
            experience, our platform needs access to certain aspects of your
            data. We want to assure you that we respect your privacy and are
            committed to handling your data responsibly and in accordance with
            all applicable data protection laws. By agreeing to this, you're
            allowing us to use your data for the sole purpose of enhancing your
            user experience on our platform. You have the right to withdraw this
            consent at any time. Please review our privacy policy for more
            information on how we use and protect your data.
          </p>
          <p className="mb-2 font normal text-gray-400">
            Do you agree to allow us to access your data? Thank you
          </p>

          <button
            className="inline-flex items-center px-3 mx-2 py-2 text-sm font-medium text-center text-white bg-primary border-2  border-gray-700 rounded-lg hover:bg-gray-600 focus:ring-4 focus:outline-none  dark:hover:bg-primary "
            onClick={handleDecline}
          >
            Decline
          </button>
          <button
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-primary border-2  border-gray-700 rounded-lg hover:bg-gray-600 focus:ring-4 focus:outline-none   "
            onClick={handleAccept}
            color="primary"
          >
            Agree
          </button>
        </div>
        {loading ? (
          <div className="loader">
            {/* <img src={loader} alt="Loading..." /> */}
          </div>
        ) : (
          <div className="container">{/* ... */}</div>
        )}
      </div>
    </>
  );
  const centeredContainer = (
    <div className="flex justify-center items-center h-screen">
      {loggedInView}
    </div>
  );

  return (
    <>
      <div id="lean-link"></div>
      {centeredContainer}
      {loading && <div className="loader">{/* ... */}</div>}
    </>
  );
};

export default EumletLinkCorporate;
