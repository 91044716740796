import React, { useState, ChangeEvent, FormEvent } from "react";
import { useAsyncError, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const CorporateForm = () => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["rtbs_token"]);
  const [isDisabled,setIsDisabled] = useState(false)

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    picture: "test",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsDisabled(true)

    fetch("https://api.eumlet.com/api/v1/auth/rtbs_auth_admin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-secret-key": "6eq6Vpc3c8tziCThs4LnKihjpeHIGAa0",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alert("Data submitted successfully");
        setCookie("rtbs_token", data.rtbs_token);
        navigate("/corporate/user");
        // Handle the response from your backend
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <section className="">
        <div className="flex flex-col items-center justify-center px-6 py-8 mt-28 md:scroll-auto lg:py-0">
          {/*<a
            href="#"
            className="flex items-center mb-6 mt-4 text-2xl font-medium text-black"

          >
             <img
              className="w-40"
              src="https://i.postimg.cc/05GjzVPC/logo-white-removebg-preview.png"
              alt="logo"
            /> 
            Corporate 
          </a> */}
          <div className="w-full border-2 bg-primary border-gray-700 shadow-md rounded-lg  dark:border md:mt-0 sm:max-w-md xl:p-0  ">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-100 md:text-2xl">
                Submit Your Details
              </h1>
              <form
                className="space-y-4 md:space-y-6"
                action="#"
                onSubmit={handleSubmit}
              >
                {" "}
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-100 "
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    required
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className=" border border-gray-600 text-gray-100 bg-gray-700 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="test@gmail.com"
                  />
                </div>
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-100 "
                  >
                    Business Name
                  </label>

                  <input
                    type="name"
                    id="name"
                    required
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="name"
                    className=" border border-gray-600 bg-gray-700 text-gray-100 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="justify-center flex">
                  {" "}
                  <button
                    type="submit"
                    disabled = {isDisabled}
                    className=" border-solid  text-white border border-gray-900 shadow-md bg-dark hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 focus:ring-gray-700 dark:hover:bg-primary-700 "
                  >
                    Submit Details
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CorporateForm;
 